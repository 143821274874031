.matrix-container {
    display: flex;
    justify-content: center;
}

.column {
    display: flex;
    flex-direction: column;
}

.column span {
    font-family: monospace;
    color: #0f0;
    white-space: pre;
}

.column span.b {
    color: #0f0;
}

.column span.l {
    color: #6f6;
}

.column span.d {
    color: #004400;
}
