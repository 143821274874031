.earn-page {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 90px;
    z-index: 999;
    background: #000;
    bottom: 0;
    color: #fff;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.show {
    opacity: 1;
}

.earn-page  h1 {
    margin-bottom: 0px;
    font-size: 28px;
}

.earn-page p {
    text-align: center;
}

.earn-page h2{
    margin-top: 32px;
    font-size: 18px;
}

.footer-earn {
    display: flex;
    justify-content: space-between;
}

.share-link {
    display: flex;
}
.referral-li {
    font-size: 14px;
}
.referral-li strong {
    color: #EBF400;
    font-size: 14px;

}

.tg-button {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background: #24A1DE;
    text-decoration: none;
    margin-top: 12px;
    padding: 12px 0;
    border-radius: 8px;
    flex-shrink: 0;

}

.footer-earn {
    display: flex;
    position: fixed;
    padding: 0 16px;
    box-sizing: border-box;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    width: 100% ;
}


.close {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    color: #fff;
    margin-top: 12px;
    padding: 12px 12px;
    width: 100%;
    font-size: 16px;
    margin-bottom: 12px;
}

.items-container {
    flex-direction: column;
}

.referral-li {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
}

.earn-page {
    overflow: scroll;
    padding-bottom: 128px;
    box-sizing: border-box;
}

.money {
    display: flex;
    align-items: center;
}

.money img {
    width: 30px;
    height: 30px;
    margin-left: 8px;
}

.footer-earn {
    background: #000;
}

.notification {
    text-align: center;
    background: rgba(255,32,78, 1);
    border-radius: 8px;
    padding: 8px;
}

.invite-friend {
    display: flex;
    flex-direction: column;
    background: rgba(255,255,255,0.1);
    border-radius: 8px;
    padding: 12px 8px;
    margin-top: 8px;
}
.invite-friend {
    text-align: center;
    align-items: center;
    font-size: 14px;
}

.invite-friend strong {
    color: #EBF400;
    font-size: 14px;
}

.invite-friend .money {
    margin-top: 6px;
}

.invite-friend .money img {
    width: 22px;
    height: 22px;
}

.earn-container {
    width: 100%;
}

.item-img img {
    width: 72px;
    height: 72px;
}

.item-img{
    width: 72px;
    height: 72px;
    border-radius: 4px;
    margin-right: 12px;
}

.item {
    background: rgba(255,255,255,0.1);
    padding: 16px;

    border-radius: 12px;
}

.item {
    display: flex;
    margin-bottom: 8px;
}

.item-coin {
    width: 18px;
    height: 18px;
    margin-left: 5px;
}

.item-price {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.item-price strong {
    margin-left: 4px;
    color: #EBF400;
}

.item-price span {
    opacity: 0.6;
}


.item-lvl {
    margin-top: 15px;
    display: flex;
}

.final-price-container {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-items: flex-end;
    justify-self: flex-end;
}

.final-price-container span{
    font-size: 18px;
    font-weight: bold;
    color: #EBF400 !important;
}

.item-info {

    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

}

.confirm-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 12px;
    min-height: 200px;
    z-index: 99999;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background: #000;
    box-sizing: border-box;
    border-top-right-radius: 22px;
    border-top: 2px solid #FF204E;
    box-shadow: -1px 0px 10px 4px #FF204E;
}

.confirm-modal-container {
    position: fixed;
    bottom: 0;
    left: 0;
    transition: all 0.3s;
    right: 0;
    top: 0;
    background: rgba(0,0,0,0.4);
}

.confirm-image {
    width: 132px;
    height: 132px;
}

.confirm-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.confirm-content h3 {
    font-size: 20px;
}

.item-price {
    margin-top: 10px;
}

.confirm-content .item-price {
    margin: 10px;
}

.confirm-content span {
    font-size: 14px;
    text-align: center;
}

.price-confirm {
    font-size: 24px;
    font-weight: bold;
    margin-top: 12px;
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}

.price-confirm  span {
    color: #EBF400 !important;
}

.price-confirm .item-coin {
    width: 26px;
    height: 26px;
}

.Button-next {
    background: #FF204E;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}


.video-play {
    background: rgba(0,0,0,0.4);

    position: fixed;

    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    display: flex;
}

.video-play video {
    background: #000;
    width: 260px;
    height: 260px;
    border-radius: 50%;
    box-shadow: 0px -1px 80px 0px #FF204E;
}

.notify {
    opacity: 0.6;
    text-align: center;
    margin-bottom: 24px;
    font-size: 14px;
}

.disabled-item {
    filter: grayscale(100%) opacity(70%);
    pointer-events: none;
}

.max-level {
    pointer-events: none;
}

.max-level-container {
    background: #333;
    display: flex;
    flex: 1;
    width: 100%;
    font-size: 12px;
    border-radius: 8px;
    padding: 6px 8px;
    margin-top: 4px;
    box-sizing: border-box;
}
