body {
  margin: 0;
  background: #000;
  font-family:  'SF Pro Rounded', -apple-system, 'SF Pro Text',
  'SF UI Text', system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  user-select: none !important;
}
main {
  background: #000;
  display: flex;
}

.Toastify__progress-bar-theme--dark {
  --toastify-color-progress-dark: #FF204E !important;
}

span {
  color: #fff !important;
}

html {
  --tg-color-scheme: #000
}

.desktop-app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;

  text-decoration: none !important;
  flex: 1;
}

.desktop-app h3{
  margin-bottom: 8px;
  font-size: 22px;
  margin-top: 100px;
}

.desktop-app a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.desktop-app-qr {
  width: 200px;
  height: 200px;
  border-radius: 16px;
  margin: 8px 0 16px 0;
}


.matrix-container {
  display: flex;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.column span {
  font-family: monospace;
  color: #0f0 !important;
  white-space: pre;
}

.column span.b {
  color: #0f0;
}

.column span.l {
  color: #6f6 !important;
}

.column span.d {
  color: #004400 !important;
}


.column img {
  width: 20px !important; /* Укажите размеры изображения */
  height: 20px !important;
}

.ton-connect-button {
  width: 220px;
}
