.animate-input input{
    width: 200px;
    height: 42px;
    background: rgba(0,0,0,0);
    border: none;
    opacity: 0;
    position: absolute;
}


.display-text {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    color: #fff;
}

.animate-input {
    height: 72px;
    width: 286px;
    background: #000 !important;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    margin-bottom: 100px;
}


.input-arrow {
    display: flex;
    flex: 1;
    width: 100%;
}

.arrow-enter {
    width: 42px;
    margin-left: 8px;
    height: 42px;
}


.min-display {
    font-size: 14px;
    margin-top: 13px;
}
