.friends-page {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 90px;
    z-index: 999;
    background: #000;
    bottom: 0;
    color: #fff;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.show {
    opacity: 1;
}

.friends-page  h1 {
    margin-bottom: 0px;
    font-size: 28px;
}

.friends-page p {
    text-align: center;
}

.friends-page h2{
    margin-top: 32px;
    font-size: 18px;
}

.footer-friends {
    display: flex;
    justify-content: space-between;
}

.share-link {
    display: flex;
}
.referral-li {
    font-size: 14px;
}
.referral-li strong {
    color: #EBF400;
    font-size: 14px;

}

.tg-button {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background: #24A1DE;
    text-decoration: none;
    margin-top: 12px;
    padding: 12px 0;
    border-radius: 8px;
    flex-shrink: 0;

}

.footer-friends {
    display: flex;
    position: fixed;
    padding: 0 16px;
    box-sizing: border-box;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    width: 100% ;
}


.close {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    color: #fff;
    margin-top: 12px;
    padding: 12px 12px;
    width: 100%;
    font-size: 16px;
    margin-bottom: 12px;
}

.referral-container {
    flex-direction: column;
}

.referral-li {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
}

.friends-page {
    overflow: scroll;
    padding-bottom: 128px;
    box-sizing: border-box;
}

.money {
    display: flex;
    align-items: center;
}

.money img {
    width: 30px;
    height: 30px;
    margin-left: 8px;
}

.footer-friends {
    background: #000;
}

.notification {
    text-align: center;
    background: rgba(255,32,78, 1);
    border-radius: 8px;
    padding: 8px;
}

.invite-friend {
    display: flex;
    flex-direction: column;
    background: rgba(255,255,255,0.1);
    border-radius: 8px;
    padding: 12px 8px;
    margin-top: 8px;
}
.invite-friend {
    text-align: center;
    align-items: center;
    font-size: 14px;
}

.invite-friend strong {
    color: #EBF400;
    font-size: 14px;
}

.invite-friend .money {
    margin-top: 6px;
}

.invite-friend .money img {
    width: 22px;
    height: 22px;
}

.friends-container {
    width: 100%;
}

.top-h1 {
    text-align: center;
    width: 100%;
    flex: 1;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
}
