.root {
  color: #fff;
}

.container {
  perspective: 300px;
}
.notcoin {
  width: 240px;
  min-width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
  min-height: 240px;
  border-radius: 50%;
  text-align: center;
  font-size: 72px;
  font-weight: bold;
  text-align: center;
  line-height: 280px;
  background-size: cover;
  margin: 0 auto;
  background: #000;
  box-shadow: inset -1px 0px 36px 17px #FF204E;
  transition: transform 0.1s ease-out;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  touch-action: none;
  user-zoom: fixed;
  transform-origin: center;
  will-change: transform;
  position: relative;
  z-index: 2;
}

.notcoin:before {
  height: 239px;
  position: absolute;
  width: 239px;
  border-radius: 50%;
  content: "";
  z-index: 1;
  border: 16px solid #FF204E;
}

.sleep {
  filter: brightness(0.8) grayscale(1);
}
.cooldown {
  width: 300px;
  height: 300px;
  transform: rotateY(-180deg) rotateZ(-90deg);
  &Container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
  }
  &Number {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: rgba(255, 255, 255, 0.35);
    font-size: 66px;
    font-weight: 900;
    line-height: 290px;
    width: 300px;
  }
  &Circle {
    stroke-dasharray: 900px;
    stroke-dashoffset: 900px;
    stroke-linecap: round;
    stroke-width: 17px;
    stroke: rgba(255, 255, 255, 0.35);
    fill: none;
    animation: countdown 10s linear forwards;
  }
}

.clickAmount {
  top: 0;
  left: 0;
  position: absolute;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  user-select: none;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 900px;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.avatar {
  width: 164px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar video {
  width: 240px;
  border-radius: 50%;
}

.video-footage::after {
  content: '';
  position: absolute;
  width: 240px;
  height: 240px;
}


.avatar-footage-container::before {
  box-shadow: inset -1px 0px 36px 17px #FF204E;
  content: "";
  width: 240px;
  border-radius: 50%;
  height: 240px;
  position: absolute;
}
