.coin-img {
  width: 48px;
  height: 48px;
}
.coin-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
}

.coin-line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vh;
}

.coin-line h1 {
  color: #fff;
  font-size: 30px;
  margin-left: 10px;
  margin-bottom: 0;
  margin-top: 0;
}

.coin-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 90vh;
  border-top-left-radius: 22px;
  padding-top: 4vh;
  box-sizing: border-box;
  border-top-right-radius: 22px;
  border-top: 2px solid #FF204E;
  box-shadow: -1px 0px 10px 4px #FF204E;
}

.Progress-container {
  width: 90%;
  height: 8px;
  border-radius: 4px;
  background: #1e1e1e;
  margin-bottom: 5vh;
}

.type-container {
  color: #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 90%;
  margin-top: 3vh;
  margin-bottom: 8px;
}

.type-container strong {
  color: #fff;
}

.boost-container {
  display: flex;
  align-items: center;
  width: 90%;
  display: flex;
  margin-top: 20px;
  color: #fff;
}

.boost-icon {
  color: #F72798;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.Progress-active {
  width: 30%;
  height: 8px;
  border-radius: 4px;
  background:  #FF204E;
}

.menu-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8px 8px;
  z-index: 4;
  width: 90%;
}

.menu-block {
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,0.1);
  display: flex;
  gap: 2px;
  color: #fff;
  padding: 10px 0;
  box-sizing: border-box;
  text-decoration: none !important;
  flex: 1;
}

.menu-block span {
  font-size: 12px;
  text-decoration: none;
  opacity: 0.8;
}

.menu-block {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
}
.menu-block strong {
  font-size: 30px;
}

.menu-icon {
  width: 26px;
  height: 26px;
  margin-bottom: 6px;
}

.footer-light {
  box-shadow: 0px 0px 101px 34px #FF204E;
  width: 100%;
  height: 1px;
  position: fixed;
  bottom: -1px
}

.video-footage-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  left: 0;
  right: 0;
  height: 320px;
  top: 20vh;
  z-index: 9;
  flex-direction: column;
  opacity: 0;
  transition: 500ms all;
}

.next-level {
  font-size: 30px;
  text-transform: uppercase;
  color: #fff;
}

.video-footage {
  width: 288px;
}

.header-container {
  height: 78px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  width: 100%;
  padding: 0 28px;
  box-sizing: border-box;

  color: #fff;
}

.avatar-container {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 8px;
  background: #fff;
}

.loader-spin {
  width: 200px;
  height: 200px;
  animation:spin 4s linear infinite;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

.header-li {
  font-size: 12px;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 0px;
  border-radius: 10px;
}
.header-li strong {
  font-size: 14px;
}
.header-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 10px;
  gap: 6px 8px;
}

.pointer-none {
  pointer-events: none !important;
}



.preload-container  {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
}

.preload-container  span {
  margin-top: 44px;
  color: #fff;
}

.avatar {
  width: 164px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar video {
  width: 240px;
  border-radius: 50%;
}

.video-footage::after {
  content: '';
  position: absolute;
}



.avatar-footage-container::before {
  box-shadow: inset -1px 0px 36px 17px #FF204E;
  content: "";
  width: 240px;
  border-radius: 50%;
  height: 240px;
  position: absolute;
}

.enter-code {
  width: 32px;
  height: 32px;
  color: #fff;
  position: absolute;
  right: 20px;
  margin-top: -18px;
  cursor: pointer;
}

.boost-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boost-icon-container {
  display: flex;
  align-items: center;
}


.winners-btn {
  background: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  border-radius: 6px;
  height: 42px;
}


.winners-btn img {
  width: 26px;
}
