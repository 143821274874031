.workProgress {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 90px;
    z-index: 999;
    background: #000;
    bottom: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}

.show {
    opacity: 1;
}

.workProgress video{
    width: 260px;
}

.workProgress h3 {
    font-size: 18px;
    color: #fff;
}

.workProgress .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    font-size: 14px;
    box-sizing: border-box;
    text-align: center;
}


.tg-button {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background: #24A1DE;
    text-decoration: none;
    margin-top: 12px;
    padding: 12px 0;
    border-radius: 8px;
    flex-shrink: 0;
}

.close {
    color: #fff;
    margin-top: 12px;
    padding: 12px 12px;
    width: 100%;
    font-size: 16px;
}