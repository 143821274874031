.onboarding-container {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #fff;
}


.onboarding-navigation {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 90%;
}

.dot {
    height: 12px;
    width: 12px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: grey;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.dot.active {
    background-color: #FF204E;
}

.onboarding-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.videos-content {
    width: 260px !important;
    height: 260px !important;
    border-radius: 50%;
}

h1 {
    max-width: 90%;
    text-align: center;
    font-size: 20px;
}

.shadow {
    display: flex;
    width: 260px;
    height: 260px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
    box-shadow: 0px -1px 80px 0px #FF204E;
    margin-bottom: 20vh;
}


.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.Button-next {
    background: #FF204E;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Button-next {
    display: flex;
    padding: 14px 22px;
    border-radius: 4px;
}

.Button-next.full {
    width: 100%;
    display: flex;
    flex: 1;
}

.hide {
    opacity: 0;
}

.button-link {
    display: flex;
    flex: 1;
    color: #fff;
    text-transform: none;
    text-decoration: none;
}

.preload-container  {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
}

.preload-container  span {
    margin-top: 44px;
    color: #fff;
}

.hide-block {
    opacity: 0;
    z-index: -1;
    position: fixed;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.shadow video {
    transition: opacity 0.3s ease-in-out;
    object-fit: cover;

}
.fade {
    opacity: 0;
}

.buttons-first {
    width: 88%;
}

.buttons-first {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons-first span {
    font-size: 22px;

}

.buttons-first img {
    width: 186px;
    height: 186px;
}

.buttons-first .Button-next {
    flex: 1 0;
    padding: 0;
    height: 42px;
    margin-top: 32px;
}

.blink {
    animation: blink 2s infinite; /* Параметры анимации */
    margin-top: 0;
}
@keyframes blink {
    from { opacity: 1; /* Непрозрачный текст */ }
    to { opacity: 0.5; /* Прозрачный текст */ }
}

.preload-container span {
    margin-top: 12px;
}

.finger {
    font-size: 32px;
}

.blink {
    width: 100%;
    text-wrap: inherit;
    font-size: 10px;
    overflow: hidden;
    text-align: center;
}

.loader-spin {
    width: 200px;
    height: 200px;
    animation:spin 4s linear infinite;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}